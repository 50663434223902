/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  box-shadow: 0 0 0px 1000px #00000000 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.errorContainer {
  z-index: 999;
  position: absolute;
  box-sizing: border-box;
  top: -10px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 8px 16px;
  background: #C05463;
  width: 100%;
}

.expandable {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  transform-origin: left top;
  transform: scaleY(0);
 }
 
 .expandable.show {
  transform: scaleY(1);
}
